<template>
  <tile>
    <div
      v-if="loading"
      class="dashboard__loader"
    >
      <loader />
    </div>
    <div
      v-else
      class="d-flex justify-between"
    >
      <div class="info__block ml-10">
        <div class="info__block-row">
          <span class="info__block-name">Название</span>
          <span class="info__block-info">Jelika / Кофта женская Джейсон свитер женский водолазка женская тёплая джемпер женский весна</span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Артикул продавца</span>
          <span class="info__block-info">5555-7777</span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Артикул WB</span>
          <span class="info__block-info">12345678987</span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Категория</span>
          <span class="info__block-info">Женская одежда</span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Категория ABC</span>
          <span class="info__block-info">AA</span>
        </div>
      </div>
      <div class="info__block">
        <div class="info__block-row">
          <span class="info__block-name">Остаток FBO</span>
          <span class="info__block-info">
            5
            <span class="info__block-anchor">Закончится через 7 дней</span>
          </span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Остаток FBS</span>
          <span class="info__block-info">
            0
            <span class="info__block-anchor">Закончится через 7 дней</span>
          </span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Себестоимость, руб</span>
          <span class="info__block-info">300</span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Маржинальность</span>
          <span class="info__block-info">
            <b class="marg">255 ₽</b>
          </span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Скорость продаж в день</span>
          <span class="info__block-info">4,65 шт</span>
        </div>
        <div class="info__block-row">
          <span class="info__block-name">Средняя цена заказа</span>
          <span class="info__block-info">555 ₽</span>
        </div>
      </div>
    </div>
  </tile>
</template>

<script>
import Tile from '@/components/Common/Tile.vue'
import Loader from '@/components/Common/Loader.vue'
import '@/styles/grafics.styl'

export default {

  components: {
    Tile,
    Loader
  },

  props: {
    points: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    points: {
      handler (value) {
        this.top_5 = []
        value.product_name.forEach((item, index) => {
          const result = {
            id: value.product_id[index],
            name: item,
            money: Number(value.orders_money[index]),
            percent: Math.round(Number(value.orders_money_diff[index]))
          }
          this.top_5.push(result)
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="stylus" scoped>
  .header-areas
    margin-right 4px

  .info__icon
    opacity 0.5

  .info__block
    width 50%
    margin-bottom 10px

  .info__block-row
    display flex
    gap 10px
    justify-content flex-start
    margin-bottom 10px

  .info__block-anchor
    text-decoration none
    color #2E68FB

  .info__block-name
    display inline-block
    width 30%

  .info__block-info
    display inline-block
    width 50%

  .info__block-info .marg {
    background-color #D9FFEC
    padding 4px
    border-radius 2px
  }

  .last-date
    display inline-block
    background-color #FFF8C7
    border-radius 14px
    padding 0 6px
    font-size 12px
    font-weight normal
    margin-left 4px
    white-space nowrap

  .semibold
    font-weight 600
</style>
